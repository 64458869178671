import React from "react";
import app from "firebase/app";
import { BrowserRouter, Switch } from "react-router-dom";
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core";
import { ThemeProvider } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { routes, ProtectedRoute } from "./router";
import { darkTheme, lightTheme } from "./theme";
import { AppState, userAction } from "./types";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const darkMode = useSelector<AppState>((s) => s.darkMode);
  app.auth().onIdTokenChanged((user) => {
    console.log(user);
    dispatch(userAction(user));
  });

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
          <BrowserRouter>
            <div className="App">
              <Switch>
                {routes.map((route) => (
                  <ProtectedRoute key={route.path} {...route} />
                ))}
              </Switch>
            </div>
          </BrowserRouter>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default App;
