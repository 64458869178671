import React from 'react';
import { 
	Card, 
	CardContent, 
	Divider, 
	Tooltip,
	Typography, 
	makeStyles 
} from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import CardHeader from './CardHeader';

type Props = {
	icon: React.ComponentType<SvgIconProps>,
	color: string,
	header: string,
	value: string,
	detail: string
};

const DataCard: React.FC<Props> = (props: Props) => {
	const classes = useStyles();
	return (
		<Card style={{height: 175}}>
			<CardContent className={classes.content}>
				<CardHeader icon={props.icon} color={props.color} header={props.header} />
				<Typography 
					className={classes.value} 
					variant="h3" 
					align="right" 
					noWrap	
				>
					{props.value}
				</Typography>
				<Divider />
				<Tooltip title={props.detail} placement="bottom-end">
					<Typography noWrap align="right" className={classes.detail}>
						{props.detail}
					</Typography>
				</Tooltip>
			</CardContent>
		</Card>
	);
};

const useStyles = makeStyles(theme => ({
	content: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		paddingBottom: theme.spacing(1.5) + 'px !important'
	},
	value: {
		flexGrow: 1
	},
	detail: {
		minHeight: 24
	}
}));

export default DataCard;