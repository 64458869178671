import * as React from "react";
import ReactCardFlip from "react-card-flip";
import {
  CardContent,
  Card as UICard,
  makeStyles,
  Theme
} from "@material-ui/core";
import {
  Coffee as CoffeeIcon,
  Infinity as InfinityIcon
} from "mdi-material-ui";
import { lightTheme } from "../theme";
import { Card, CardBack, CardType } from "../types";

type Props = {
  card: Card;
  cardBack: CardBack;
  flipped: boolean;
  height: number;
};

const ScrumCard: React.FC<Props> = (props: Props) => {
  const { card, cardBack, flipped } = props;
  const [isFlipped, setIsflipped] = React.useState(flipped);
  const flippedTimeoutId = React.useRef<number>();

  const classes = useStyles(props);

  React.useEffect(() => {
    if (flippedTimeoutId.current) {
      clearTimeout(flippedTimeoutId.current);
    }
    flippedTimeoutId.current = setTimeout(() => {
      console.log("setting flipped: " + flipped);
      setIsflipped(flipped);
    }, 500);
  }, [flipped]);

  let cardBackImage;
  switch (cardBack) {
    case CardBack.AvantiCard:
      cardBackImage = "avanti_card";
      break;
    case CardBack.TraditionalBlue:
      cardBackImage = "traditional_blue";
      break;
    case CardBack.TraditionalRed:
      cardBackImage = "traditional_red";
      break;
    case CardBack.TraditionalGreen:
      cardBackImage = "traditional_green";
      break;
    case CardBack.TraditionalDarkGrey:
      cardBackImage = "traditional_dark_grey";
      break;
    case CardBack.FloralDarkBlue:
      cardBackImage = "floral_dark_blue";
      break;
    case CardBack.FloralGreyBlue:
      cardBackImage = "floral_grey_blue";
      break;
    case CardBack.FloralLightBlue:
      cardBackImage = "floral_light_blue";
      break;
    case CardBack.FloralRed:
      cardBackImage = "floral_red";
      break;
    case CardBack.SquareDiamondBlue:
      cardBackImage = "square_diamond_blue";
      break;
    case CardBack.SquareDiamondBrown:
      cardBackImage = "square_diamond_brown";
      break;
    case CardBack.SquareDiamondRed:
      cardBackImage = "square_diamond_red";
      break;
    case CardBack.SquareDiamondYellowRed:
      cardBackImage = "square_diamond_yellow_red";
      break;
    case CardBack.SunDarkBlue:
      cardBackImage = "sun_dark_blue";
      break;
    case CardBack.SunDarkGrey:
      cardBackImage = "sun_dark_grey";
      break;
    case CardBack.SunLightBlue:
      cardBackImage = "sun_light_blue";
      break;
    case CardBack.SunRed:
      cardBackImage = "sun_red";
      break;
    case CardBack.FancyPattern1:
      cardBackImage = "fancy_pattern_1";
      break;
    case CardBack.FancyPattern2:
      cardBackImage = "fancy_pattern_2";
      break;
    case CardBack.FancyPattern3:
      cardBackImage = "fancy_pattern_3";
      break;
    case CardBack.FancyPattern4:
      cardBackImage = "fancy_pattern_4";
      break;
    case CardBack.FancyPattern5:
      cardBackImage = "fancy_pattern_5";
      break;
    case CardBack.FancyPattern6:
      cardBackImage = "fancy_pattern_6";
      break;
    case CardBack.FancyPattern7:
      cardBackImage = "fancy_pattern_7";
      break;
    case CardBack.FancyPattern8:
      cardBackImage = "fancy_pattern_8";
      break;
  }

  let cardText;
  if (card.type === CardType.Coffee) {
    cardText = <CoffeeIcon className={classes.cardIcon} />;
  } else if (card.type === CardType.Infinite) {
    cardText = <InfinityIcon className={classes.cardIcon} />;
  } else {
    cardText = card.text;
  }

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <UICard
        className={classes.card}
        style={{ backgroundImage: `url(/images/${cardBackImage}_front.webp)` }}
      >
        <CardContent className={classes.cardContent}>{cardText}</CardContent>
      </UICard>
      <UICard
        className={classes.card}
        style={{ backgroundImage: `url(/images/${cardBackImage}_back.webp)` }}
      />
    </ReactCardFlip>
  );
};

const useStyles = makeStyles<Theme, Props>(theme => ({
  card: {
    height: props => props.height,
    width: props => Math.round(props.height * 0.712),
    backgroundColor: "silver",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    fontSize: 64,
    color: lightTheme.palette!.text!.primary
  },
  cardContent: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center"
  },
  cardIcon: {
    fontSize: 64,
    color: lightTheme.palette!.text!.primary
  }
}));

export default ScrumCard;
