import "firebase/auth";
import app from "firebase/app";
import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import {
  AppBar,
  Avatar,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {
  Account as AccountIcon,
  ChevronLeft as ChevronLeftIcon,
  ExitToApp as LogoutIcon,
  Information as InfoIcon,
  MoonWaningCrescent as DarkIcon,
  WhiteBalanceSunny as LightIcon,
} from "mdi-material-ui";
import { AppState, darkModeAction } from "../types";
import Copyright from "./Copyright";
import Snackbar from "./Snackbar";

type Props = {
  title: string;
  drawer?: JSX.Element;
  drawerButtonIcon?: JSX.Element;
  drawerButtonTooltip?: string;
  appBarButtons?: JSX.Element;
};

const MenuHeader = styled(Typography)`
  ${({ theme }: { theme: Theme; component: string }) => `
    text-align: center;
    padding: ${theme.spacing(0, 2)};
  `}
`;

const MenuDivider = styled(Divider)`
  ${({ theme }: { theme: Theme; component: string }) => `
    margin: ${theme.spacing(1, 0)};
  `}
`;

const Layout: React.FC<Props> = (props) => {
  const {
    appBarButtons,
    drawer,
    drawerButtonIcon,
    drawerButtonTooltip,
    title,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.user);
  const darkMode = useSelector((state: AppState) => state.darkMode);
  const theme = useTheme();
  const [open, setOpen] = React.useState(drawer ? true : false);
  const [accountMenuOpen, setAccountMenuOpen] = React.useState(false);
  const accountButtonRef = React.useRef<Element | null>(null);

  function toggleDrawer() {
    setOpen((current) => !current);
  }

  function toggleDarkMode() {
    dispatch(darkModeAction(!darkMode));
  }

  let initials = null;
  const pieces = user?.displayName?.split(" ");
  if (pieces) {
    if (pieces.length > 2) {
      // Name has multiple spaces so just display the initial of the first name.
      initials = pieces[0].substring(0, 1);
    }
    if (pieces.length === 2) {
      initials = pieces[0].substring(0, 1) + pieces[1].substring(0, 1);
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, { [classes.appBarShift]: open })}
      >
        <Toolbar>
          {drawer && (
            <Tooltip
              title={
                drawerButtonTooltip ? drawerButtonTooltip : "Toggle Drawer"
              }
            >
              <IconButton color="inherit" onClick={toggleDrawer}>
                {drawerButtonIcon ? drawerButtonIcon : <InfoIcon />}
              </IconButton>
            </Tooltip>
          )}
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
          <div className={classes.grow} />
          {appBarButtons}
          <Tooltip title="Toggle dark mode">
            <IconButton
              className="app-bar-button"
              color="inherit"
              aria-label="Toggle dark mode"
              onClick={toggleDarkMode}
              edge="end"
            >
              {darkMode ? <LightIcon /> : <DarkIcon />}
            </IconButton>
          </Tooltip>
          {user != null && (
            <>
              <IconButton
                className="app-bar-button"
                ref={(ref) => (accountButtonRef.current = ref)}
                onClick={() => setAccountMenuOpen(true)}
              >
                <Avatar
                  alt={user.displayName ? user.displayName : ""}
                  src={user.photoURL ? user.photoURL : ""}
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: theme.palette.primary.dark,
                  }}
                >
                  {!initials && <AccountIcon />}
                  {initials}
                </Avatar>
              </IconButton>
              <Menu
                open={accountMenuOpen}
                onClose={() => setAccountMenuOpen(false)}
                anchorEl={accountButtonRef.current}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{
                  style: { borderRadius: 0 },
                }}
              >
                <MenuHeader variant="body1" component="li">
                  {user.displayName}
                </MenuHeader>
                <MenuHeader variant="body2" component="li">
                  {user.email}
                </MenuHeader>
                <MenuDivider component="li" />
                <MenuItem onClick={() => app.auth().signOut()}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <Typography variant="inherit">Logout</Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
      {drawer && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.appBarSpacer}>
            <IconButton onClick={() => setOpen(false)}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {drawer}
        </Drawer>
      )}
      <main
        className={clsx(
          classes.content,
          { [classes.contentClosedDrawer]: drawer },
          { [classes.contentOpenDrawer]: open }
        )}
      >
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl">{props.children}</Container>
      </main>
      <footer className={classes.footer}>
        <Copyright />
      </footer>
      <Snackbar />
    </div>
  );
};

const drawerWidth = 300;
const useStyles = makeStyles((theme) => ({
  "@global": {
    ".app-bar-button": {
      marginLeft: theme.spacing(1),
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  spacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  appBarSpacer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  contentClosedDrawer: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentOpenDrawer: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}));

export default Layout;
