import React, { ComponentType } from "react";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps
} from "react-router-dom";
import { useSelector } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import { AppState, RouteInfo } from "./types";
import Error from "./shared/Error";
import Sessions from "./sessions/Sessions";
import Board from "./board/Board";
import Login from "./login/Login";

export const ProtectedRoute: ComponentType<RouteProps & RouteInfo> = ({
  render,
  Component,
  isProtected,
  ...routeProps
}) => {
  const user = useSelector((state: AppState) => state.user);
  const routeRender = (props: RouteComponentProps) => {
    if (user === undefined) {
      return <LinearProgress />;
    } else if (isProtected && user === null && routeProps.path !== "/") {
      return (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      );
    }
    return render ? render(props) : <Component />;
  };
  return <Route {...routeProps} render={routeRender} />;
};

export const routes: RouteInfo[] = [
  {
    path: "/sessions",
    Component: Sessions,
    isProtected: true
  },
  {
    path: "/board/:sessionID",
    Component: Board,
    isProtected: false
  },
  {
    path: "/error/:code",
    Component: Error,
    isProtected: false
  },
  {
    path: "/",
    Component: Login,
    isProtected: false
  }
];
