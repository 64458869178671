import * as React from "react";
import {
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  AccountRemove as RemoveIcon,
  UndoVariant as ResetIcon,
  Replay as ResetAllIcon,
} from "mdi-material-ui";
import ParticipantCard from "./ParticipantCard";
import {
  removeParticipant,
  resetParticipant,
  resetAllParticipants,
} from "../firebase";
import * as Types from "../types";
import { Participant } from "../types";

type Props = {
  graphVisible: boolean;
  hideVotes: boolean;
  isOwner: boolean;
  participants: Types.Participant[];
};

const ParticipantsCard: React.FC<Props> = (props: Props) => {
  const { graphVisible, hideVotes, isOwner, participants } = props;
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const anchorElRef = React.useRef<Element | null>(null);
  const participantRef = React.useRef<Participant>();

  function onParticipantAction(element: Element, participant: Participant) {
    participantRef.current = participant;
    anchorElRef.current = element;
    setMenuOpen(true);
  }

  function onRemove() {
    let participant = participantRef.current;
    if (participant && participant.id) {
      removeParticipant(participant.id);
    }
    setMenuOpen(false);
  }

  function onReset() {
    let participant = participantRef.current;
    if (participant && participant.id) {
      resetParticipant(participant.id);
    }
    setMenuOpen(false);
  }

  function onResetAll() {
    resetAllParticipants(participants);
    setMenuOpen(false);
  }

  let gridContent;
  if (participants.length > 0) {
    gridContent = participants.map((participant) => (
      <Grid
        item
        xs={12}
        md={graphVisible ? 12 : 6}
        lg={graphVisible ? 6 : 4}
        xl={3}
        key={participant.id}
      >
        <ParticipantCard
          hideVote={hideVotes}
          participant={participant}
          onAction={
            isOwner ? (el) => onParticipantAction(el, participant) : undefined
          }
        />
      </Grid>
    ));
  } else {
    gridContent = (
      <Grid item xs={12} className={classes.emptyPlaceholder}>
        <Typography>Waiting for participants to join...</Typography>
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        {gridContent}
      </Grid>
      <Menu
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorEl={anchorElRef.current!}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "center", horizontal: "center" }}
        PaperProps={{
          style: { borderRadius: 0 },
        }}
      >
        {participantRef.current && !participantRef.current.flipped && (
          <MenuItem onClick={onReset}>
            <ListItemIcon>
              <ResetIcon />
            </ListItemIcon>
            <Typography variant="inherit">Reset Vote</Typography>
          </MenuItem>
        )}
        {participants && participants.findIndex((p) => !p.flipped) > -1 && (
          <MenuItem onClick={onResetAll}>
            <ListItemIcon>
              <ResetAllIcon />
            </ListItemIcon>
            <Typography variant="inherit">Reset All Votes</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={onRemove}>
          <ListItemIcon>
            <RemoveIcon />
          </ListItemIcon>
          <Typography variant="inherit">Remove Participant</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  emptyPlaceholder: {
    display: "flex",
    height: 200,
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default ParticipantsCard;
