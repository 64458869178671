import { createMuiTheme } from "@material-ui/core";
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from "@material-ui/core/colors";

export const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: blue,
    secondary: orange,
    success: {
      main: "#28A745",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FFC107",
      contrastText: "#343A40",
    },
  },
});

export const lightTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: blue,
    secondary: orange,
    text: {
      primary: grey[800],
    },
    success: {
      main: "#28A745",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FFC107",
      contrastText: "#343A40",
    },
  },
});

export const colors: string[] = [
  red[500],
  pink[500],
  purple[500],
  deepPurple[500],
  indigo[500],
  blue[500],
  cyan[500],
  teal[500],
  green[500],
  lightGreen[500],
  lime[500],
  yellow[500],
  amber[500],
  orange[500],
  deepOrange[500],
  brown[500],
  grey[500],
  blueGrey[500],
];
