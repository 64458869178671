import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar as MUISnackbar, makeStyles } from "@material-ui/core";
import {
  Alert as WarningIcon,
  AlertCircle as ErrorIcon,
  CheckCircle as CheckCircleIcon
} from "mdi-material-ui";
import { AppState, MessageType, clearMessage } from "../types";

const minDuration = 5000;

const Snackbar: React.FC = () => {
  const message = useSelector((s: AppState) => s.message);
  const dispatch = useDispatch();
  const classes = useStyles();

  function onClear() {
    dispatch(clearMessage());
  }

  if (!message) {
    return null;
  }

  let messageClass;
  let messageIcon;
  const duration =
    message.duration > 0 && message.duration < minDuration
      ? minDuration
      : message.duration;
  const messageText = message.text;
  switch (message.type) {
    case MessageType.Success:
      messageClass = classes.success;
      messageIcon = <CheckCircleIcon className={classes.messageIcon} />;
      break;
    case MessageType.Warning:
      messageClass = classes.warning;
      messageIcon = <WarningIcon className={classes.messageIcon} />;
      break;
    case MessageType.Error:
      messageClass = classes.error;
      messageIcon = <ErrorIcon className={classes.messageIcon} />;
      break;
  }
  return (
    <MUISnackbar
      open={message ? true : false}
      ContentProps={{
        className: messageClass
      }}
      autoHideDuration={duration < 1 ? null : duration}
      onClick={onClear}
      onClose={(_, reason) => {
        if (reason !== "clickaway") {
          onClear();
        }
      }}
      message={
        <span className={classes.message}>
          {messageIcon}
          {messageText}
        </span>
      }
    />
  );
};

const useStyles = makeStyles(theme => ({
  messageIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  }
}));

export default Snackbar;
