import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

type Props = {
	icon: React.ComponentType<SvgIconProps>,
	color: string,
	header: string
};

const CardHeader: React.FC<Props> = (props: Props) => {
	return (
		<Box style={{display: 'flex', justifyContent: 'space-between'}}>
			<props.icon style={{fontSize: 32}} htmlColor={props.color} />
			<Typography variant="h4" style={{color: props.color}}>{props.header}</Typography>	
		</Box>
	);
};

export default CardHeader;