import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBLKwbBg3zbRsXgGJBuvytL8OV982ZiTrw",
  authDomain: "scrumpoker.avanti.dev",
  databaseURL: "https://scrum-poker-d423a.firebaseio.com",
  projectId: "scrum-poker-d423a",
  storageBucket: "scrum-poker-d423a.appspot.com",
  messagingSenderId: "844739856468",
  appId: "1:844739856468:web:0f0e1654d7ebb3af52c5c1",
  measurementId: "G-YFN59T4GT5"
};
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
