import * as React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Container,
  CssBaseline,
  Link,
  Theme,
  Typography
} from "@material-ui/core";
import { AlertOutline } from "mdi-material-ui";
import styled from "styled-components";
import { AppState, ErrorCode } from "../types";

const Root = styled.div`
  ${({ theme }: { theme: Theme }) => `
  text-align: center;
  min-height: 100vh;
  padding-top: ${theme.spacing(2)}px;
  ${[theme.breakpoints.up("sm")]} {
    padding-top: ${theme.spacing(10)}px;
  }
`}
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WarningIcon = styled(AlertOutline)`
  ${({ theme }: { theme: Theme }) => `
  font-size: 36px;
  margin-right: ${theme.spacing(2)}px;
`}
`;

const Logo = styled.img`
  ${({ theme }: { theme: Theme }) => `
  max-width: 350px;
  margin-bottom: ${theme.spacing(10)}px
`}
`;

const Error: React.FC = () => {
  let { code } = useParams();
  const darkMode = useSelector((state: AppState) => state.darkMode);
  const user = useSelector((state: AppState) => state.user);

  const errorCode = parseInt(code ?? "0") as ErrorCode;
  let message;
  switch (errorCode) {
    case ErrorCode.SessionDoesNotExist:
      message = "Session does not exist!";
      break;
    default:
      message = "An error occured, please try again!";
      break;
  }

  return (
    <Root>
      <CssBaseline />
      <Container component="main" maxWidth="md">
        <Logo
          src={`/images/scrum_poker_logo_${darkMode ? "dark" : "light"}.webp`}
          alt="Avanti Scrum Poker"
        />
        <HeaderContainer>
          <WarningIcon />
          <Typography variant="h4">{message}</Typography>
        </HeaderContainer>
        <Link href={user == null ? "/login" : "/session"}>
          Click here to start over!
        </Link>
      </Container>
    </Root>
  );
};

export default Error;
