import React from "react";
import { useDispatch } from "react-redux";
import { Backdrop, Box, Link, Typography, makeStyles } from "@material-ui/core";
import { QRCodeGeneratorComponent } from "@syncfusion/ej2-react-barcode-generator";
import { lightTheme } from "../theme";
import { messageAction } from "../types";

type Props = {
  sessionID: string;
};

const boardURL = "https://scrumpoker.avanti.dev/board";

const BoardDrawer: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [fullScreen, setFullScreen] = React.useState(false);

  function copyLinkToClipboard(e: React.SyntheticEvent) {
    e.preventDefault();
    navigator.clipboard
      .writeText(`${boardURL}/${props.sessionID}`)
      .then(() => {
        dispatch(messageAction("Link copied to clipboard!"));
      })
      .catch(() => {
        dispatch(messageAction("Failed to copy link to clipboard!"));
      });
  }

  return (
    <div className={classes.root}>
      <div className={classes.infoContainer}>
        <Typography variant="body1">
          To join press the Join Session button in the app and scan the QR code
          or enter the code below. Session code is case-sensitive so enter it
          exactly as it appears on the screen.
        </Typography>
      </div>
      <div className={classes.infoContainer}>
        <Typography variant="h6">Session Code</Typography>
        <Typography variant="body1" gutterBottom>
          {props.sessionID}
        </Typography>
        <QRCodeGeneratorComponent
          id="barcode"
          width="100%"
          height={250}
          displayText={{ visibility: false }}
          mode="SVG"
          type="QRCode"
          value={props.sessionID}
          onClick={() => setFullScreen(true)}
          style={{ opacity: fullScreen ? 0.05 : 1, cursor: "pointer" }}
        />
      </div>
      <div className={classes.infoContainer}>
        <Typography variant="h6">Session Link</Typography>
        <Link className={classes.link} href="#" onClick={copyLinkToClipboard}>
          {boardURL}/{props.sessionID}
        </Link>
      </div>
      <Backdrop open={fullScreen} onClick={() => setFullScreen(false)}>
        <Box className={classes.fullScreenBox}>
          <Typography
            variant="h5"
            gutterBottom
            className={classes.fullScreenCode}
          >
            {props.sessionID}
          </Typography>
          <QRCodeGeneratorComponent
            id="barcode-full-screen"
            width={600}
            height={600}
            displayText={{ visibility: false }}
            mode="SVG"
            type="QRCode"
            value={props.sessionID}
          />
        </Box>
      </Backdrop>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: theme.spacing(2, 1),
  },
  infoContainer: {
    marginBottom: theme.spacing(6),
  },
  fullScreenBox: {
    borderRadius: 5,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(6),
    backgroundColor: "#fff",
  },
  fullScreenCode: {
    color: lightTheme.palette!.text!.primary,
  },
  link: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    wordBreak: "break-word",
    hyphens: "auto",
  },
}));

export default BoardDrawer;
