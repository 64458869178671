import React from "react";
import app from "firebase/app";
import { CardBack, CardType, MessageType } from "./enums";

export type RouteInfo = {
  path: string;
  isProtected: boolean;
  Component: React.ComponentType<any>;
};

export type Message = {
  type: MessageType;
  text: string;
  duration: number;
};

export type Card = {
  type: CardType;
  text: string;
};

export type Doc = {
  id?: string;
  ref?: firebase.firestore.DocumentReference;
};

export type Session = {
  owner: string;
  created: string;
  message?: string;
  participantCount: number;
  votesHidden: boolean;
} & Doc;

export type Participant = {
  displayName: string;
  cardBack: CardBack;
  card: Card;
  flipped: boolean;
  session: firebase.firestore.DocumentReference;
} & Doc;

export enum SocketMessageType {
  Session = 0,
  SetMessage,
  SetCard,
  FlipCard,
  AddParticipant,
  RemoveParticipant,
  UpdateParticipant,
}

export enum SocketEvent {
  Open = 0,
  Close,
  Error,
  Failed,
}

export type SocketMessage = {
  type: SocketMessageType;
  data: any;
};

export type KeyValuePair<K, V> = {
  key: K;
  value: V;
};

export enum SnackbarType {
  Default = 0,
  Success,
  Warning,
  Error,
}

export type AppState = {
  user?: app.User | null;
  darkMode: boolean;
  message?: Message;
};
