import { createStore } from 'redux';
import { PayloadAction } from 'typesafe-actions';
import { ActionTypes, AppState } from './types';

const localStoragePrefix = 'store.';

function getPersisted(key: string, defaultValue: boolean): boolean {
	const value = localStorage.getItem(localStoragePrefix + key);
	if (value) {
		 // eslint-disable-next-line eqeqeq
		return value == 'true';
	}
	return defaultValue;
};

function persist(key: string, value: any) {
	localStorage.setItem(localStoragePrefix + key, value);
};

const initialState: AppState = {
	darkMode: getPersisted(ActionTypes.DarkMode, true)
};

const reducer = (state: AppState = initialState, action: PayloadAction<ActionTypes, any>) => {
	switch (action.type) {
		case ActionTypes.User:
			const user = action.payload;
			return { ...state, user };
		case ActionTypes.DarkMode:
			persist(action.type, action.payload);
			const darkMode = action.payload;
			return { ...state, darkMode };
		case ActionTypes.Message:
			const message = action.payload;
			return { ...state, message }
		default:
			return state;
	}
}

export default createStore(reducer);
