import "firebase/auth";
import React from "react";
import styled from "styled-components";
import app from "firebase/app";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Button as MuiButton,
  CssBaseline,
  Container,
  Typography,
} from "@material-ui/core";
import { AppState } from "../types";
import Copyright from "../shared/Copyright";

// #region styles

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
`;

const Form = styled.div`
  margin-top: ${({ theme }) => `${theme.spacing(8)}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  margin: ${({ theme }) => `${theme.spacing(4)}px`};
`;

const ButtonContainer = styled(Container)`
  margin-top: ${({ theme }) => `${theme.spacing(6)}px`};
`;

const ButtonLogoWrapper = styled.div`
  width: 30px;
  background-color: #ffffff;
  border-radius: 4px;
  border-width: 1px;
  border-color: #ffffff;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonLogo = styled.img`
  width: 100%;
  height: 100%;
`;

const ButtonText = styled.div`
  flex: 1;
  paddingright: 30px;
`;

const Button = styled(MuiButton)`
  display: flex;
  margin: ${({ theme }) => `${theme.spacing(2, 0)}`};
  width: 100%;
`;

const Footer = styled.footer`
  padding: ${({ theme }) => `${theme.spacing(3, 2)}`};
  margin-top: auto;
`;

// #endregion

const Login: React.FC = () => {
  const user = useSelector((s: AppState) => s.user);
  const darkMode = useSelector((state: AppState) => state.darkMode);

  function onLogin(google: boolean) {
    const provider = google
      ? new app.auth.GoogleAuthProvider()
      : new app.auth.OAuthProvider("microsoft.com");
    app.auth().signInWithPopup(provider);
  }

  if (user) {
    return <Redirect to="/sessions" />;
  }

  return (
    <Root>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Form>
          <Logo
            src={`./images/scrum_poker_logo_${darkMode ? "dark" : "light"}.svg`}
            alt=""
            role="presentation"
          />
          <Typography component="h1" variant="h5" className="visually-hidden">
            Avanti Scrum Poker
          </Typography>
          <Typography>
            A remote planning poker app created by Avanti Software.
          </Typography>
          <ButtonContainer maxWidth="xs">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="default"
              onClick={() => onLogin(false)}
            >
              <ButtonLogoWrapper>
                <ButtonLogo src="./images/ms.svg" alt="Microsoft" />
              </ButtonLogoWrapper>
              <ButtonText>Sign In With Microsoft</ButtonText>
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => onLogin(true)}
            >
              <ButtonLogoWrapper>
                <ButtonLogo src="./images/google.png" alt="Google" />
              </ButtonLogoWrapper>
              <ButtonText>Sign In With Google</ButtonText>
            </Button>
          </ButtonContainer>
        </Form>
      </Container>
      <Footer>
        <Copyright />
      </Footer>
    </Root>
  );
};

export default Login;
