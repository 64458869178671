import * as React from "react";
import {
  Avatar,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { DotsVertical as ActionsIcon } from "mdi-material-ui";
import ScrumCard from "./ScrumCard";
import { Participant } from "../types";

type Props = {
  hideVote: boolean;
  participant: Participant;
  onAction?: (el: Element) => void;
};

const ParticipantCard: React.FC<Props> = (props) => {
  const { hideVote, participant, onAction } = props;
  const classes = useStyles();
  const { displayName, card, cardBack, flipped } = participant;
  const actionButtonRef = React.useRef<Element | null>(null);

  function initials(name: string) {
    var initials = "";
    if (name) {
      const parts = name.trim().split(" ");
      if (parts.length > 0 && parts[0][0]) {
        initials += parts[0][0].toUpperCase();
        const lastIndex = parts.length - 1;
        if (lastIndex > 0 && parts[lastIndex][0]) {
          initials += parts[lastIndex][0].toUpperCase();
        }
      }
    }
    return initials;
  }

  function avatarColour(text: string) {
    var hash = 0;
    for (var i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (let i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return {
      backgroundColor: colour,
    };
  }

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <div className={classes.centerContent}>
          <Avatar className={classes.avatar} style={avatarColour(displayName)}>
            <Typography variant="h4">{initials(displayName)}</Typography>
          </Avatar>
          <Typography noWrap display="block">
            {displayName}
          </Typography>
        </div>
        <ScrumCard
          height={150}
          card={card}
          cardBack={cardBack}
          flipped={hideVote || flipped}
        />
        {onAction && (
          <Tooltip title="Actions">
            <IconButton
              ref={(ref) => (actionButtonRef.current = ref)}
              className={classes.removeIcon}
              onClick={() => onAction(actionButtonRef.current!)}
            >
              <ActionsIcon />
            </IconButton>
          </Tooltip>
        )}
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  cardContent: {
    display: "flex",
    position: "relative",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  centerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  avatar: {
    margin: 10,
    width: 100,
    height: 100,
    color: "#FFFFFF",
  },
  removeIcon: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

export default ParticipantCard;
