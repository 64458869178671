import "firebase/firestore";
import * as firebase from "firebase/app";
import { useProduction } from "./index";
import { Collection, Participant, Session } from "../types";

const firestore = () => {
  let db = firebase.firestore();
  if (!useProduction && window.location.hostname === "localhost") {
    db.settings({
      host: "localhost:6000",
      ssl: false,
    });
  }
  return db;
};

export const createSession = (uid: string): Promise<string> => {
  const sessionRef = firestore().collection(Collection.Sessions).doc();
  return sessionRef
    .set({
      created: new Date(),
      owner: uid,
      message: "",
      participantCount: 0,
    })
    .then(() => Promise.resolve(sessionRef.id));
};

export const setSessionMessage = (sessionID: string, message: string) => {
  firestore()
    .collection(Collection.Sessions)
    .doc(sessionID)
    .set({ message }, { merge: true });
};

export const setSessionVotesHidden = (
  sessionID: string,
  votesHidden: boolean
) => {
  firestore()
    .collection(Collection.Sessions)
    .doc(sessionID)
    .set({ votesHidden }, { merge: true });
};

export const observeSessions = (
  uid: string,
  observer: (sessions: Session[]) => void
) => {
  return firestore()
    .collection(Collection.Sessions)
    .where("owner", "==", uid)
    .onSnapshot((snapshot) => {
      observer(
        snapshot.docs.map(
          (d) => ({ id: d.id, ref: d.ref, ...d.data() } as Session)
        )
      );
    });
};

export const observeSession = (
  sessionID: string,
  observer: (session: Session) => void
) => {
  return firestore()
    .collection(Collection.Sessions)
    .doc(sessionID)
    .onSnapshot((snapshot) => {
      observer({
        id: snapshot.id,
        ref: snapshot.ref,
        ...snapshot.data(),
      } as Session);
    });
};

export const observerParticipants = (
  sessionID: string,
  observer: (participants: Participant[]) => void
) => {
  let db = firestore();
  return db
    .collection(Collection.Participants)
    .where("session", "==", db.collection(Collection.Sessions).doc(sessionID))
    .onSnapshot((snapshot) => {
      observer(
        snapshot.docs.map(
          (p) => ({ id: p.id, ref: p.ref, ...p.data() } as Participant)
        )
      );
    });
};

export const removeParticipant = (participantID: string) => {
  return firestore()
    .collection(Collection.Participants)
    .doc(participantID)
    .delete();
};

export const resetParticipant = (participantID: string) => {
  return firestore().collection(Collection.Participants).doc(participantID).set(
    {
      flipped: true,
    },
    { merge: true }
  );
};

export const resetAllParticipants = (participants: Participant[]) => {
  let batch = firestore().batch();
  for (var i = 0; i < participants.length; i++) {
    let participant = participants[i];
    if (participant.flipped) {
      continue;
    }
    batch.set(
      participants[i].ref!,
      {
        flipped: true,
      },
      { merge: true }
    );
  }
  return batch.commit();
};

export const endSession = (sessionID: string): Promise<void> => {
  let db = firestore();
  let sessionRef = db.collection(Collection.Sessions).doc(sessionID);
  let batch = db.batch();
  return db
    .collection(Collection.Participants)
    .where("session", "==", sessionRef)
    .get()
    .then((snapshot) => {
      for (let i = 0; i < snapshot.size; i++) {
        batch.delete(snapshot.docs[i].ref);
      }
      return Promise.resolve();
    })
    .then(() => batch.delete(sessionRef))
    .then(() => batch.commit());
};
