export enum Collection {
  Participants = "participants",
  Sessions = "sessions"
}

export enum CardType {
  Text = 0,
  Coffee,
  Infinite
}

export enum CardBack {
  AvantiCard = 0,
  TraditionalBlue,
  TraditionalRed,
  TraditionalGreen,
  TraditionalDarkGrey,
  FloralDarkBlue,
  FloralGreyBlue,
  FloralLightBlue,
  FloralRed,
  SquareDiamondBlue,
  SquareDiamondBrown,
  SquareDiamondRed,
  SquareDiamondYellowRed,
  SunDarkBlue,
  SunDarkGrey,
  SunLightBlue,
  SunRed,
  FancyPattern1,
  FancyPattern2,
  FancyPattern3,
  FancyPattern4,
  FancyPattern5,
  FancyPattern6,
  FancyPattern7,
  FancyPattern8
}

export enum MessageType {
  Default = 0,
  Success,
  Warning,
  Error
}

export enum ErrorCode {
  Unhandled = 0,
  SessionDoesNotExist = 2
}
