import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Grid,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ViewDashboard as BoardIcon } from "mdi-material-ui";
import { createSession, observeSessions, endSession } from "../firebase";
import { AppState, Session, MessageType, messageAction } from "../types";
import Layout from "../shared/Layout";

const Dashboard: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((s: AppState) => s.user);
  const darkMode = useSelector((state: AppState) => state.darkMode);
  const [sessions, setSessions] = React.useState<Session[]>([]);
  const [busy, setBusy] = React.useState(false);

  function onCreateSession() {
    if (busy || !user) {
      return;
    }
    if (sessions.length > 4) {
      alert("You can only have 5 active sessions at a time.");
      return;
    }
    setBusy(true);
    createSession(user!.uid)
      .then((sessionID) => {
        onView(sessionID);
      })
      .catch(() => {
        setBusy(false);
      });
  }

  function onView(sessionID: string) {
    history.push("/board/" + sessionID);
  }

  function onEnd(session: Session) {
    if (!window.confirm("Are you sure you want to end this session?")) {
      return;
    }
    endSession(session.id!)
      .then(() => {
        dispatch(
          messageAction("Session ended successfully!", MessageType.Success)
        );
      })
      .catch(() => {
        dispatch(
          messageAction(
            "An error occured, please try again later.",
            MessageType.Error
          )
        );
      });
  }

  function title(session: Session) {
    const { id } = session;
    return id;
  }

  function subheader(session: Session) {
    const { message, participantCount } = session;
    let participantsText;
    if (participantCount === 1) {
      participantsText = "1 participant";
    } else if (participantCount > 1) {
      participantsText = `${participantCount} participants`;
    } else {
      participantsText = "No participants";
    }
    return (
      <>
        {message && message.length > 0 && <div>{message}</div>}
        <div>{participantsText}</div>
      </>
    );
  }

  React.useEffect(() => {
    const unsubscribe = observeSessions(user!.uid, (s) => setSessions(s));
    return () => {
      unsubscribe();
    };
  }, [user]);

  return (
    <Layout title="Sessions">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} className={classes.info}>
          <Box className={classes.gutterBottom}>
            <img
              className={classes.logo}
              src={`/images/scrum_poker_logo_${
                darkMode ? "dark" : "light"
              }.svg`}
              alt="Welcome to Avanti Scrum Poker"
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={onCreateSession}
            disabled={busy}
          >
            Start New Poker Session
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            Active Sessions
          </Typography>
          {sessions.length === 0 && (
            <Typography>
              You have no active planning poker sessions,{" "}
              <Link className={classes.createLink} onClick={onCreateSession}>
                create one
              </Link>{" "}
              to get started!
            </Typography>
          )}
          {sessions.map((session) => (
            <Card key={session.id!} className={classes.gutterBottom}>
              <CardHeader
                avatar={
                  <Avatar>
                    <BoardIcon />
                  </Avatar>
                }
                title={title(session)}
                subheader={subheader(session)}
              />
              <CardActions className={classes.actions}>
                <Button size="small" onClick={() => onEnd(session)}>
                  End Session
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => onView(session.id!)}
                >
                  View Board
                </Button>
              </CardActions>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  info: {
    textAlign: "center",
    paddingTop: `${theme.spacing(6)}px !important`,
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  logo: {
    maxWidth: 425,
  },
  createLink: {
    cursor: "pointer",
  },
}));

export default Dashboard;
