import app from "firebase/app";
import { action } from "typesafe-actions";
import { MessageType } from "./enums";
import { Message } from "./types";

export enum ActionTypes {
  User = "USER",
  DarkMode = "DARK_MODE",
  Message = "MESSAGE",
}

export const userAction = (user: app.User | null) =>
  action(ActionTypes.User, user);
export const darkModeAction = (darkMode: boolean) =>
  action(ActionTypes.DarkMode, darkMode);
export const clearMessage = () => action(ActionTypes.Message, undefined);
// export const defaultMessageAction = (message: string | undefined) =>
//   action(ActionTypes.Message, { type: MessageType.Default, text: message });
export const messageAction = (
  text: string,
  type = MessageType.Default,
  duration: number = 5000
) => action(ActionTypes.Message, { type, text, duration } as Message);
