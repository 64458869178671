import "hammerjs";
import "@progress/kendo-theme-material/scss/dataviz.scss";
import * as React from "react";
import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import { Card as MUICard, CardContent } from "@material-ui/core";
import { ChartDonut as ChartIcon } from "mdi-material-ui";
import { useTheme } from "@material-ui/core/styles";
import { colors } from "../theme";
import CardHeader from "./CardHeader";
import { Card } from "../types";

type DataItem = {
  category: string;
  value: number;
  color?: string;
};

type Props = {
  cards: Card[];
  color: string;
  votesHidden: boolean;
};

const ResultsChart: React.FC<Props> = (props) => {
  const { cards, color, votesHidden } = props;
  const theme = useTheme();

  const data: DataItem[] = [];
  if (!votesHidden) {
    cards.forEach((card) => {
      const existingDataItem = data.find((d) => d.category === card.text);
      if (existingDataItem) {
        existingDataItem.value++;
      } else {
        data.push({ category: card.text, value: 1 });
      }
    });
  }

  function labelContent(e: any) {
    return `${e.category}`;
  }

  function renderTooltip(context: any) {
    const { category, series, value } = context.point || context;
    return (
      <>
        <div>
          {series.name}: {category}
          <br />
          Votes: {value}
        </div>
      </>
    );
  }

  return (
    <MUICard>
      <CardContent>
        <CardHeader icon={ChartIcon} color={color} header="Results" />
        <Chart
          seriesColors={colors}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ChartLegend
            position="right"
            labels={{ color: theme.palette.text.primary }}
            visible={false}
          />
          <ChartArea background="none" />
          <ChartTooltip render={renderTooltip} />
          <ChartSeries>
            <ChartSeriesItem
              name="Effort"
              type="donut"
              data={data}
              field="value"
              categoryField="category"
              labels={{ visible: true }}
              dynamicHeight
            >
              <ChartSeriesLabels
                color="#fff"
                background="none"
                content={labelContent}
                align="center"
                position="center"
              />
            </ChartSeriesItem>
          </ChartSeries>
        </Chart>
      </CardContent>
    </MUICard>
  );
};

export default ResultsChart;
