import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, IconButton, TextField, Theme, Tooltip } from "@material-ui/core";
import {
  HelpCircleOutline as HelpIcon,
  Information as InfoIcon,
  FormatListBulleted as ListIcon,
  ChartDonut as ChartIcon,
  EmoticonSadOutline as SadFaceIcon,
  ThumbUp as ThumbUpIcon,
  Eye as EyeIcon,
  EyeOff as EyeOffIcon,
} from "mdi-material-ui";
import { amber, cyan, deepOrange, teal } from "@material-ui/core/colors";
import {
  observeSession,
  setSessionMessage,
  observerParticipants,
  setSessionVotesHidden,
} from "../firebase";
import {
  AppState,
  Card,
  KeyValuePair,
  Participant,
  Session,
  messageAction,
} from "../types";
import Layout from "../shared/Layout";
import ResultsChart from "./ResultsChart";
import DataCard from "./DataCard";
import ParticipantsCard from "./ParticipantsCard";
import BoardDrawer from "./BoardDrawer";

const OptionsGridItem = styled(Grid)`
  display: flex;
  align-items: flex-start;
`;

const RevealIconButton = styled(IconButton)`
  flex-grow: 0;
  margin: ${({ theme }: { theme: Theme }) => `${theme.spacing(1, 0, 0, 1)}`};
`;

const Board: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user);
  const onMessageChangedTimeout = React.useRef<number>();
  const [session, setSession] = React.useState<Session>();
  const [message, setMessage] = React.useState<string>("");
  const [participants, setParticipants] = React.useState<Participant[]>([]);
  const [graphVisible, setGraphVisible] = React.useState(true);
  const isOwner = user != null && user.uid === session?.owner;
  const votesHidden = session?.votesHidden === true;
  let { sessionID } = useParams();

  function onMessageChanged(e: React.ChangeEvent<HTMLInputElement>) {
    let msg = e.target.value;
    setMessage(msg);
    if (onMessageChangedTimeout.current) {
      clearTimeout(onMessageChangedTimeout.current);
    }
    onMessageChangedTimeout.current = setTimeout(() => {
      setSessionMessage(sessionID!, msg);
    }, 2000);
  }

  const redirect = React.useCallback(() => {
    history.replace(user == null ? "/login" : "/sessions");
  }, [history, user]);

  React.useEffect(() => {
    const unsubscribeObserveSession = observeSession(
      sessionID!,
      (updatedSession) => {
        setMessage(updatedSession.message ? updatedSession.message : "");
        setSession(updatedSession);
      }
    );
    const unsubscribeObserveParticipants = observerParticipants(
      sessionID!,
      (participants) => {
        console.log("setting participants: " + participants);
        setParticipants((current) => {
          let diff = participants.length - current.length;
          if (diff === 1) {
            let participant = participants.find(
              (p) => current.findIndex((c) => p.id === c.id) === -1
            );
            if (participant) {
              dispatch(
                messageAction(`${participant.displayName} joined the session.`)
              );
            }
          } else if (diff === -1) {
            let participant = current.find(
              (p) => participants.findIndex((c) => p.id === c.id) === -1
            );
            if (participant) {
              dispatch(
                messageAction(`${participant.displayName} left the session.`)
              );
            }
          } else if (diff > 1) {
            dispatch(messageAction(`${diff} participants joined the session.`));
          } else if (diff < -1) {
            dispatch(
              messageAction(`${Math.abs(diff)} participants left the session.`)
            );
          }
          return participants;
        });
      }
    );
    return () => {
      unsubscribeObserveSession();
      unsubscribeObserveParticipants();
    };
  }, [dispatch, redirect, sessionID]);

  if (!sessionID) {
    redirect();
    return null;
  }

  function cardKey(card: Card) {
    return card.type + "." + card.text;
  }

  const cards: Card[] = participants
    .filter((c) => !c.flipped)
    .map((p) => p.card);
  var cardCount = new Map<string, number>();
  cards.forEach((card) => {
    const key = cardKey(card);
    var count = cardCount.get(key);
    if (!count) {
      count = 0;
    }
    cardCount.set(key, ++count);
  });
  var largest: number;
  var smallest: number;
  cardCount.forEach((value, key) => {
    if (!largest || value > largest) {
      largest = value;
    }
    if (!smallest || value < smallest) {
      smallest = value;
    }
  });
  var highestVotes: KeyValuePair<string, number>[] = [];
  var lowestVotes: KeyValuePair<string, number>[] = [];
  cardCount.forEach((value, key) => {
    if (largest === value) {
      highestVotes.push({ key, value });
    }
    if (smallest === value) {
      lowestVotes.push({ key, value });
    }
  });

  function votesToString(
    votes: KeyValuePair<string, number>[],
    keyword?: string
  ) {
    if (votesHidden || participants.length === 0) {
      return "";
    }
    if (!votes || votes.length === 0) {
      return keyword ? "No participants have responded" : "";
    }
    var detail = "";
    if (votes.length === 1) {
      const card = cards.find((c) => cardKey(c) === votes[0].key);
      detail = card!.text;
      if (keyword) {
        detail += ` had the ${keyword} votes with ${votes[0].value}`;
      }
    } else {
      for (let iVote = 0; iVote < votes.length; iVote++) {
        const card = cards.find((c) => cardKey(c) === votes[iVote].key);
        const isLast = keyword && iVote === votes.length - 1;
        if (isLast) {
          detail += " and ";
        } else if (iVote > 0) {
          detail += ", ";
        }
        detail += card!.text;
      }
      if (keyword) {
        detail += ` had the ${keyword} amount of votes with ${votes[0].value}`;
      }
    }
    return detail;
  }

  function generateNoResponseDetail(count: number) {
    if (participants.length === 0) {
      return "";
    } else if (count === 0) {
      return "All participants responded.";
    } else if (count === 1) {
      return "One participant did not respond.";
    }
    return count + " participants did not respond.";
  }

  let mostVotes = votesToString(highestVotes);
  let mostVotesInDetail = votesToString(highestVotes, "most");
  var leastVotes = votesToString(lowestVotes);
  let leastVotesInDetail;
  if (leastVotes === mostVotes) {
    leastVotes = "";
    leastVotesInDetail = "";
  } else {
    leastVotesInDetail = votesToString(lowestVotes, "least");
  }
  let noResponseValue = participants.filter((p) => p.flipped).length;
  let noResponeDetail = generateNoResponseDetail(noResponseValue);
  let appBarButtons = (
    <>
      <Tooltip title="View Sessions">
        <IconButton
          className="app-bar-button"
          color="inherit"
          onClick={() => history.push("/sessions")}
          edge="end"
        >
          <ListIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Toggle Results Chart">
        <IconButton
          className="app-bar-button"
          color="inherit"
          onClick={() => setGraphVisible((visible) => !visible)}
          edge="end"
        >
          <ChartIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  return (
    <Layout
      title="Poker Board"
      drawer={<BoardDrawer sessionID={sessionID} />}
      drawerButtonIcon={<InfoIcon />}
      drawerButtonTooltip="Toggle Session Information"
      appBarButtons={appBarButtons}
    >
      <Grid container spacing={2}>
        <OptionsGridItem item xs={12}>
          <TextField
            id="outlined-basic"
            label="Board Message"
            margin="dense"
            style={{ flex: 1 }}
            variant="outlined"
            value={message}
            onChange={onMessageChanged}
            disabled={!isOwner}
            helperText={
              isOwner
                ? "Do not share sensitive information in the board message as sessions are publicly available to anyone with a code."
                : null
            }
          />
          {isOwner && (
            <Tooltip title={votesHidden ? "Reveal Votes" : "Hide Votes"}>
              <RevealIconButton
                onClick={() => {
                  setSessionVotesHidden(sessionID!, !votesHidden);
                }}
              >
                {votesHidden && <EyeIcon />}
                {!votesHidden && <EyeOffIcon />}
              </RevealIconButton>
            </Tooltip>
          )}
        </OptionsGridItem>
        <Grid item xs={12} md={6} lg={4}>
          <DataCard
            icon={ThumbUpIcon}
            color={teal[300]}
            header="Most Votes"
            value={mostVotes}
            detail={mostVotesInDetail}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DataCard
            icon={SadFaceIcon}
            color={deepOrange[300]}
            header="Least Votes"
            value={leastVotes}
            detail={leastVotesInDetail}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DataCard
            icon={HelpIcon}
            color={amber[500]}
            header="No Response"
            value={noResponseValue.toString()}
            detail={noResponeDetail}
          />
        </Grid>
        {graphVisible && (
          <Grid item xs={12} md={6} lg={4}>
            <ResultsChart
              cards={cards}
              color={cyan[500]}
              votesHidden={votesHidden}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={graphVisible ? 6 : 12}
          lg={graphVisible ? 8 : 12}
        >
          <ParticipantsCard
            graphVisible={graphVisible}
            hideVotes={votesHidden}
            isOwner={isOwner}
            participants={participants}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Board;
